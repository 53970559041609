import { gql } from "@apollo/client";

export const RECIPE_FIELDS = gql`
	fragment RecipeFields on RecipeRecord {
		slug
	}
`;

export const LANDING_FIELDS = gql`
	fragment LandingFields on NewLandingPageRecord {
		slug
	}
`;

export const NAVIGATION = gql`
	${RECIPE_FIELDS}
	${LANDING_FIELDS}
	fragment NavigationFields on NavigationRecord {
		pages {
			title
			link {
				__typename
				...RecipeFields
				...LandingFields
			}
			childItems {
				link {
					__typename
					...RecipeFields
					...LandingFields
				}
				title
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 128, h: 128, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
				childItems {
					link {
						__typename
						...RecipeFields
						...LandingFields
					}
					title
				}
			}
		}
	}
`;

export const fetchNavigationData = gql`
	${NAVIGATION}
	query FetchNavigationData {
		navigation {
			__typename
			...NavigationFields
		}
	}
`;
