import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import Fuse from "fuse.js";

import { useFunnelStore } from "@/store/FunnelStore";
import { track } from "@/helpers/analytics";
import { FunnelRoutes, useFunnelNavigator } from "@/hooks/useFunnelNavigator";
import { Fields } from "@/types/pet";
import { useUserDataStore } from "@/store/UserDataStore";
import {
	AmplitudeExperimentContext,
	AmplitudeExperiments,
} from "@/providers/ExperimentsProvider";

import { useFunnelStepHelper } from "../useFunnelStepHelper";

// RADIO BUTTON OPTIONS
export const DietOptions = [
	{
		id: "kibble biscuits",
		label: "Kibble",
		info: "Brown biscuits",
	},
	{
		id: "wet food",
		label: "Wet food",
		info: "Tins or trays",
	},
	{
		id: "raw",
		label: "Raw",
		info: "Uncooked meats",
	},
	{
		id: "home prepared meals",
		label: "Home-made",
		info: "Cooked at home",
	},
	{
		id: "pre-cooked food",
		label: "Pre-cooked",
		info: "Cooked & frozen",
	},
	{
		id: "freeze-dried",
		label: "Freeze-dried",
		info: "Or cold pressed",
	},
];

type FormValues = {
	diets: {
		diet: (typeof DietOptions)[number]["id"][] | undefined;
		brands: string;
	}[];
};

export const useDiet = () => {
	const experiments = useContext(AmplitudeExperimentContext);

	const [funnelData, setPropertyWithId, fetchBrands, brands] = useFunnelStore(
		(state) => {
			return [
				state.data,
				state.setPropertyWithId,
				state.fetchBrands,
				state.brands,
			];
		}
	);

	const hasSetInitialValues = useRef(false);

	const { isReady, query, push } = useRouter();

	const user = useUserDataStore((state) => state.user);

	const [didYouMean, setDidYouMean] = useState<
		{ value: string; label: string }[]
	>([]);

	const timeoutForFindMisspeltBrand = useRef<ReturnType<
		typeof setTimeout
	> | null>(null);

	const { setFunnelDataFromPet } = useFunnelStepHelper();

	const { goToNextStep, buttonBarProps, stageNo, totalSteps } =
		useFunnelNavigator({
			stepName: FunnelRoutes.DIET,
		});

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		setFocus,
		formState: { errors },
		control,
	} = useForm<FormValues>({ mode: "onChange" });

	const hasSetScrollToTop = useRef(false);

	const { fields, append } = useFieldArray<FormValues>({
		control,
		name: "diets",
	});

	useEffect(() => {
		if (!isReady) {
			return;
		}

		if (hasSetInitialValues.current) {
			return;
		}

		const { id } = query;
		// to force the type to string (it won't be anything else)
		const activePetId = (id || "") as string;

		if (!activePetId) {
			hasSetInitialValues.current = true;
			if (!funnelData[0] && Object.values(user?.pets || {}).length < 1) {
				push("../signup");

				return;
			} else if (!funnelData[0] && user.pets) {
				setFunnelDataFromPet(Object.values(user.pets)[0]);

				return;
			}
			append({
				diet: funnelData?.[0]?.[Fields.DIET],
				brands: funnelData?.[0]?.[Fields.BRANDS]?.join(", ") || "",
			});

			return;
		}

		const activePetData = funnelData?.find(
			({ name }) => name?.toLowerCase() === activePetId.toLowerCase()
		);

		append({
			diet: activePetData?.[Fields.DIET],
			brands: activePetData?.[Fields.BRANDS]?.join(", ") || "",
		});

		hasSetInitialValues.current = true;
	}, [
		append,
		funnelData,
		isReady,
		push,
		query,
		setFunnelDataFromPet,
		setValue,
		user.pets,
	]);

	useEffect(() => {
		track("Funnel Section Complete", {
			stage: "Diet",
			type: "step",
			stageNo,
		});
	}, [stageNo]);

	const onSubmit = useCallback(
		(data: FormValues) => {
			data.diets.forEach(({ diet, brands }, index) => {
				const id = funnelData[index][Fields.PETID];

				if (!id) {
					return;
				}

				setPropertyWithId(id, {
					[Fields.DIET]: diet,
					[Fields.BRANDS]: brands
						?.split(",")
						.map((brand) => brand.trim())
						.filter((brand) => !!brand),
				});
			});

			goToNextStep();
		},
		[funnelData, goToNextStep, setPropertyWithId]
	);

	const value = watch("diets");

	// if we ever support multi dog, this won't work.
	const dietValue = watch("diets.0.diet");

	const brandsValue = watch("diets.0.brands");

	const brandOptions = useMemo(() => {
		if (!brands.length) {
			fetchBrands();

			return [];
		}

		const dietNameValues = dietValue?.map(
			(diet) => DietOptions?.find(({ id }) => id === diet)?.label || ""
		);

		return brands
			?.filter(({ name, format }) => {
				const allBrands = brandsValue?.split(",");

				if (
					allBrands?.find(
						(brand) => brand.trim().toLowerCase() === name.trim().toLowerCase()
					)
				) {
					return false;
				}

				return dietNameValues?.find((diet) => {
					return format?.find(
						(formatItem) =>
							formatItem.toLowerCase().trim() === diet.toLowerCase().trim()
					);
				});
			})
			.map((brand) => {
				return {
					value: brand.name,
					label: brand.name,
				};
			});
	}, [brands, brandsValue, dietValue, fetchBrands]);

	// Page loads at the bottom as it appends content, we want to wait until
	// the field values have been set then set scroll position to top, only on once of course.
	useEffect(() => {
		if (
			value &&
			value.length === funnelData.length &&
			!hasSetScrollToTop.current
		) {
			window.scrollTo({ top: 0, behavior: "instant" });
			hasSetScrollToTop.current = true;
		}
	}, [funnelData.length, value]);

	const onChange = useCallback(
		(newVal: (typeof DietOptions)[number]["id"], index: number) => {
			if (!value || !Array.isArray(value)) {
				setValue(`diets.${index}.diet`, [newVal]);

				return;
			}

			if (value[index].diet && value[index].diet?.includes(newVal)) {
				const values = value[index].diet?.filter((item) => item !== newVal);
				setValue(`diets.${index}.diet`, values);

				return;
			}

			setValue(`diets.${index}.diet`, [...(value[index].diet || []), newVal]);
		},
		[setValue, value]
	);

	const findMisspeltBrand = useCallback(
		(val: string) => {
			if (!brandOptions || !brandOptions.length || !val) {
				return;
			}

			// SHOW BEST RESULTS BASED ON SPELLING ATTEMPTS RATHER THAN DIRECT MATCHES
			const fuse = new Fuse(brandOptions, {
				keys: ["value"],
				threshold: 0.4,
			});

			if (!fuse) {
				return;
			}

			const closeMatchResults = fuse?.search(val.trim());

			closeMatchResults.length &&
				setDidYouMean(
					closeMatchResults
						.flatMap(({ item }) => item)
						?.map((brand) => {
							return {
								value: brand.value,
								label: brand.label,
							};
						})
				);
		},
		[brandOptions]
	);

	const onBrandsInputChange = useCallback(
		(val: string, index: number) => {
			const eachBrand = val?.split(",");
			const mostRecentTypedBrand = eachBrand[eachBrand.length - 1];

			const cantFindBrand =
				val &&
				!brands?.find(
					({ name }) =>
						name.trim().toLowerCase() ===
						mostRecentTypedBrand.trim().toLowerCase()
				);

			setValue(`diets.${index}.brands`, val);

			if (!value || !cantFindBrand) {
				return;
			}

			timeoutForFindMisspeltBrand.current &&
				clearTimeout(timeoutForFindMisspeltBrand.current);

			timeoutForFindMisspeltBrand.current = setTimeout(
				() => findMisspeltBrand(mostRecentTypedBrand),
				300
			);
		},
		[brands, findMisspeltBrand, setValue, value]
	);

	const onBrandsChangeSelect = useCallback(
		(option: string, index: number) => {
			const splitValue = brandsValue?.trim()?.split(",");
			splitValue[splitValue.length - 1] = option;

			setValue(
				`diets.${index}.brands`,
				`${splitValue.map((val) => val.trim()).join(", ")}, `
			);
		},
		[brandsValue, setValue]
	);

	const canSubmit = !!value?.every(({ diet }) => diet && diet.length > 0);

	const shouldShowSelect = useCallback(
		(index: number) => {
			if (
				value[index].diet &&
				value[index].diet.length === 1 &&
				value[index].diet[0] === "home prepared meals"
			) {
				return false;
			}

			return !!value[index].diet?.length;
		},
		[value]
	);

	return {
		handleSubmit,
		onSubmit,
		options: DietOptions,
		register,
		errors,
		onChange,
		setValue,
		value,
		buttonBarProps,
		stageNo,
		totalSteps,
		fields,
		funnelData,
		canSubmit,
		onBrandsInputChange,
		onBrandsChangeSelect,
		didYouMean,
		brandOptions,
		setFocus,
		shouldShowSelect,
	};
};
