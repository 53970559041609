import c from "classnames";

import { useMediaQueries } from "@/hooks/useMediaQueries";

import Styles from "./divider.module.scss";

type Props = {
	isInvalid?: boolean;
	isChecked?: boolean;
	onButtonClick?: () => void;
	hasButton?: boolean;
	children?: React.ReactNode;
	noPadding?: boolean;
	style?: Record<string, unknown>;
	className?: string;
	sizing?: "normal" | "small" | "large" | "full";
	type?: "normal" | "light" | "dashed" | "lighter" | "darkLight";
	textType?: "normal" | "light" | "lighter" | "darkLight";
	spacing?:
		| "normal"
		| "small"
		| "large"
		| "xsmall"
		| "none"
		| "medium"
		| "xxsmall";
	mobileOnly?: boolean;
	hidden?: boolean;
	backgroundColor?: string;
	dashed?: boolean;
	tabletOnly?: boolean;
};

export const Divider = ({
	style = {},
	noPadding,
	children,
	sizing = "normal",
	className = "",
	type = "normal",
	spacing = "normal",
	mobileOnly = false,
	hidden = false,
	backgroundColor,
	tabletOnly,
	textType = "normal",
}: Props) => {
	const { isMobile } = useMediaQueries();

	if ((!isMobile && mobileOnly) || (isMobile && tabletOnly)) {
		return <></>;
	}

	return (
		<div
			className={c(Styles.wrapper, className, {
				[Styles.noText]: !children,
				[Styles.noPad]: noPadding,
				[Styles[sizing + "Sizing"]]: sizing !== "normal",
				[Styles[type + "Type"]]: type !== "normal",
				[Styles[spacing + "Spacing"]]: spacing !== "normal",
				[Styles.hidden]: hidden,
				[Styles["background" + backgroundColor]]: !!backgroundColor,
			})}
			style={style}
		>
			<div className={Styles.before}></div>

			{children && (
				<div
					className={c(Styles.text, {
						[Styles[textType]]: textType !== "normal",
					})}
				>
					{children}
				</div>
			)}

			<div className={Styles.after}></div>
		</div>
	);
};
