import { ResponsiveImageType } from "react-datocms";

import { FullWidthSection } from "@/types/page";
import { GENERIC_ERROR_MSG } from "@/consts/fieldValidators";

import { initializeApollo } from "./apolloClient";
import {
	BUY_ONE_OFF_BOX,
	CHARGE_CHECKOUT,
	GET_FEATURED_PRODUCTS,
	GET_FEATURED_PRODUCTS_DASHBOARD,
	GET_MAIN_PAGE_SHOP_DATA,
} from "./queries/shop";

import { ChargeCheckoutResponse } from "../types";

type MainPageShopData = {
	data: {
		shop: {
			hero: {
				__typename: string;
			};
			ctaCarousel: {
				buttonLink: string;
				buttonText: string;
				buttonBehaviour: string;
				buttonCategoryToOpen: string;
				description: string;
				heading: string;
				image: ResponsiveImageType;
			};
		};
	};
};

export const getShopMainPageData = async (): Promise<unknown> => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_MAIN_PAGE_SHOP_DATA,
			context: { clientName: "dato" },
		})
		.then((result: MainPageShopData) => {
			return result.data?.shop;
		})
		.catch();
};

type FeaturedProducts = {
	data: {
		featuredProductList: {
			recipes: { handle: string }[];
			sideIcons: FullWidthSection["sideIcons"];
			backgroundColour: FullWidthSection["backgroundColour"];
			title: string;
		};
	};
};

export const fetchFeaturedProducts = async (): Promise<
	FeaturedProducts["data"]["featuredProductList"]
> => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_FEATURED_PRODUCTS,
			context: { clientName: "dato" },
		})
		.then((result: FeaturedProducts) => {
			return result.data?.featuredProductList;
		})
		.catch((e: unknown) => console.error(e));
};

export const fetchDashboardUpsellProducts = async (): Promise<
	FeaturedProducts["data"]["featuredProductList"]["recipes"]
> => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: GET_FEATURED_PRODUCTS_DASHBOARD,
			context: { clientName: "dato" },
		})
		.then((result: FeaturedProducts) => {
			return result.data?.featuredProductList.recipes;
		})
		.catch();
};

export const finaliseCheckout = async (id: string) => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: CHARGE_CHECKOUT,
			variables: {
				checkout_id: id,
			},
			context: { clientName: "checkoutAPI" },
		})
		.then((result: ChargeCheckoutResponse) => {
			if (result.data.chargeCheckout.stripe_error) {
				return {
					data: null,
					error: result.data.chargeCheckout.stripe_error,
				};
			}

			return {
				error: null,
				data: result.data?.chargeCheckout,
			};
		})
		.catch((err: Error) => {
			console.error("finaliseCheckout error:", err);
		});
};

type BuyOneOffBoxVariables = {
	customer_id: string;
	subscription_id: number;
	payment_id?: string;
	delivery_date: string;
	charge_delivery: boolean;
	input: {
		new_address_id?: number;
		address_id?: string;
		discount_code?: string;
		items?: {
			variant_id: string;
			quantity: number;
			default?: boolean;
			sample?: boolean;
			is_topper?: boolean;
		}[];
	};
};

type BuyOneOffBoxResponse = {
	data: {
		CreateOrder: {
			id: string;
			subscription_id: number;
			address_id: string;
			discount_code: string;
			shipping_weight: number;
			price: number;
			tax: number;
			discount: number;
			delivery: number;
			total: number;
			items: {
				id: string;
				itemable_id: number;
				itemable_type: string;
				title: string;
				variant_id: string;
				type: string;
				uses: number;
				quantity: number;
				price: number;
				default: boolean;
				created_at: string;
				updated_at: string;
			}[];
			created_at: string;
			updated_at: string;
			error: string;
		};
	};
};

export const buyOneOffBox = async (variables: BuyOneOffBoxVariables) => {
	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: BUY_ONE_OFF_BOX,
			variables: {
				...variables,
				subscription_id: parseInt(`${variables.subscription_id}`),
			} as BuyOneOffBoxVariables,
			errorPolicy: "all",
			context: { clientName: "subApp" },
		})
		.then((result: BuyOneOffBoxResponse) => {
			if (result?.data?.CreateOrder.error || !result) {
				return {
					data: null,
					error: result?.data?.CreateOrder.error || GENERIC_ERROR_MSG,
				};
			}

			return {
				error: null,
				data: result.data.CreateOrder,
			};
		})
		.catch((e: unknown) => {
			console.error(e);

			return {
				data: null,
				error: "An error occured, please try again later.",
			};
		});
};
