import c from "classnames";

import { usePriceSummary } from "./usePriceSummary";
import Styles from "./priceSummary.module.scss";

type Props = {
	showDelivery?: boolean;
	inCheckout?: boolean;
	expressCheckoutFlag?: boolean;
	colour?: "default" | "light";
};

export const PriceSummary = ({
	showDelivery = true,
	inCheckout = false,
	expressCheckoutFlag = false,
	colour = "default",
}: Props) => {
	const { checkout } = usePriceSummary();

	return checkout?.pricing ? (
		inCheckout && expressCheckoutFlag ? (
			<div className={Styles.priceSummary}>
				<div>Delivery</div>
				<div>
					{checkout.pricing.delivery && checkout.pricing.delivery > 0 ? (
						<>£{(checkout.pricing.delivery / 100).toFixed(2)}</>
					) : (
						<>FREE</>
					)}
				</div>
				{checkout.pricing.discount > 0 && (
					<>
						<div className={Styles.discount}>Total savings</div>
						<div className={Styles.discountPrice}>
							£{(checkout.pricing.discount / 100).toFixed(2)}
						</div>
					</>
				)}
				<div className={Styles.total}>Today's Total</div>
				<div className={Styles.total}>
					£{(checkout.pricing.subtotal / 100).toFixed(2)}
				</div>
			</div>
		) : (
			<div className={Styles.priceSummary}>
				{checkout.pricing.discount > 0 && (
					<>
						<div
							className={c(Styles.discount, {
								[Styles.light]: colour === "light",
							})}
						>
							Discounts
						</div>
						<div
							className={c(Styles.discountPrice, {
								[Styles.light]: colour === "light",
							})}
						>
							-£{(checkout.pricing.discount / 100).toFixed(2)}
						</div>
					</>
				)}

				{showDelivery ? (
					<>
						<div>Delivery</div>
						<div>
							{checkout.pricing.delivery && checkout.pricing.delivery > 0 ? (
								<>£{(checkout.pricing.delivery / 100).toFixed(2)}</>
							) : (
								<>Free</>
							)}
						</div>
						<div className={Styles.total}>Total</div>
						<div className={Styles.total}>
							£{(checkout.pricing.subtotal / 100).toFixed(2)}
						</div>
					</>
				) : (
					<>
						<div>Subtotal</div>
						<div
							className={c(Styles.total, {
								[Styles.light]: colour === "light",
							})}
						>
							£{(checkout.pricing.subtotal / 100).toFixed(2)}
						</div>
					</>
				)}
			</div>
		)
	) : (
		<></>
	);
};
