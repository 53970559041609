/* eslint-disable indent */
import c from "classnames";
import Link from "next/link";

import { PriceAndDiscount } from "@/components/FormElements/PriceAndDiscount";
import { LineItem } from "@/types/checkout";
import { QuantitySelector } from "@/components/FormElements/QuantitySelector";
import { Icon } from "@/components/FormElements/Icon";
import { Divider } from "@/components/FormElements/Divider";

import Styles from "./basketItem.module.scss";
import { useBasketItem } from "./useBasketItem";

type Props = {
	item: LineItem;
	isEditable?: boolean;
	isDeleteable?: boolean;
	onDelete: (id: LineItem["id"]) => void;
	inCheckout: boolean;
	expressCheckoutFlag?: boolean;
};

export const BasketItem = ({
	item,
	isEditable = true,
	isDeleteable = false,
	onDelete,
	inCheckout = false,
	expressCheckoutFlag,
}: Props) => {
	const {
		extrasCount,
		updateItem,
		fullPrice,
		fullPriceOriginal,
		quantityIsLoading,
		onKeyDown,
		recipesPriceOriginal,
		recipesPrice,
		treats,
	} = useBasketItem({
		item,
		onDelete,
	});

	if (inCheckout && expressCheckoutFlag) {
		return (
			<div className={Styles.summaryItems}>
				<div className={Styles.item}>
					<div className={Styles.itemTitle}>
						{item.pet?.name}'s transition box
					</div>
					<PriceAndDiscount
						price={recipesPriceOriginal}
						discount={(recipesPriceOriginal - recipesPrice) * 100}
						voucherTag={false}
						inCheckout={inCheckout}
					/>
				</div>

				{(extrasCount ?? 0) > 0 &&
					treats?.map((treat, index) => (
						<div key={index}>
							<div className={Styles.item}>
								<div>
									<div className={Styles.itemTitle}>{treat.product_title}</div>
									<div className={Styles.itemSize}>{treat.size}</div>
									<div className={Styles.itemQuantity}>
										Qty: {treat.quantity}
									</div>
								</div>

								<div className={Styles.itemPrice}>
									<PriceAndDiscount
										price={treat.total_original_price}
										discount={
											(treat.total_original_price - treat.total_price) * 100
										}
										voucherTag={false}
										inCheckout={inCheckout}
									/>
								</div>
							</div>
						</div>
					))}
			</div>
		);
	} else if (item?.pet?.name) {
		return (
			<>
				<div className={Styles.item}>
					<div className={Styles.itemTopLine}>
						<Link href="/signup-review">
							<div className={c(Styles.title, Styles.titleLink)}>
								{item.pet.name + "'s plan"}
							</div>
						</Link>
						<p className={c(Styles.extras, "caption light")}>
							+ {extrasCount || "no"} extras
						</p>
					</div>

					<div
						className={c(Styles.price, {
							[Styles.priceNoBottom]: (extrasCount || 0) > 0,
						})}
					>
						<PriceAndDiscount
							price={fullPriceOriginal}
							discount={(fullPriceOriginal - fullPrice) * 100}
							voucherTag={false}
							deleted={true}
							twoLines
						/>
					</div>
					{isDeleteable && (
						<div
							className={Styles.deleteIcon}
							onClick={() => onDelete(item.id)}
							tabIndex={1}
							onKeyDown={onKeyDown}
						>
							<Icon icon="TrashCan" height="24px" width="24px" />
						</div>
					)}
				</div>
				<Divider type="light" sizing="full" spacing="small" />
			</>
		);
	} else {
		return (
			<>
				{item.items.map((theItem, key) => (
					<>
						<div className={Styles.item} key={key}>
							<div className={Styles.itemTopLine}>
								<div className={Styles.title}>{theItem.product_title}</div>
								<p className={c(Styles.extras, "caption light")}>
									{theItem.weight && theItem.weight}g
								</p>
							</div>

							<div
								className={c(Styles.price, {
									[Styles.priceNoBottom]: (extrasCount || 0) > 0,
								})}
							>
								<PriceAndDiscount
									price={theItem.price / 100}
									discount={theItem.original_price - theItem.price}
									voucherTag={false}
									twoLines
								/>
							</div>
						</div>
						{isEditable && (
							<div className={Styles.itemContents}>
								<div className={Styles.itemQty}>
									<QuantitySelector
										quantity={theItem.quantity}
										isLoadingQuantity={quantityIsLoading === theItem.variant_id}
										disabled={quantityIsLoading !== ""}
										setQuantity={(newQuantity) => {
											updateItem(
												newQuantity,
												!!theItem.needs_scoops,
												theItem.variant_id
											);
										}}
									/>
								</div>
							</div>
						)}
						<Divider type="light" sizing="full" spacing="small" />
					</>
				))}
			</>
		);
	}
};
