import { useCallback } from "react";

import { useRouter } from "next/router";
import c from "classnames";

import { ButtonVariants } from "@/types/inputFields";
import { useMediaQueries } from "@/hooks/useMediaQueries";

import Styles from "./backButton.module.scss";

import { Button } from "../FormElements/Button";

export const BackButton = ({
	onBack,
	wording,
	showOnMobile = false,
	noSpacing = false,
	noMargin = false,
}: {
	onBack?: () => void;
	wording?: string;
	showOnMobile?: boolean;
	noSpacing?: boolean;
	noMargin?: boolean;
}) => {
	const { back, query, push } = useRouter();

	const { isDesktop } = useMediaQueries();

	const goBack = useCallback(() => {
		if (query.referrer) {
			const queryClone = { ...query };
			delete queryClone.referrer;

			push({
				pathname: query.referrer as string,
				query: queryClone,
			});
			return;
		}
		if (onBack) {
			onBack();
			return;
		}
		back();
	}, [back, onBack, push, query]);

	if (isDesktop || showOnMobile) {
		return (
			<div
				className={c("text-left", Styles.backButton, {
					[Styles.noSpacing]: noSpacing,
					[Styles.noMargin]: noMargin,
				})}
			>
				<Button
					width="relative"
					variant={ButtonVariants.ghost}
					leftIcon={{ icon: "BackArrow" }}
					onClick={goBack}
					type="button"
				>
					{wording ? wording : "Back"}
				</Button>
			</div>
		);
	} else {
		return null;
	}
};
