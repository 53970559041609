import c from "classnames";
import { useSession } from "next-auth/react";

import { Icon } from "@/components/FormElements/Icon";
import { PriceSummary } from "@/pagesComponents/Checkout/PriceSummary/PriceSummary";
import { useUserDataStore } from "@/store/UserDataStore";
import { Button } from "@/components/FormElements/Button";
import { BasketItem } from "@/pagesComponents/Checkout/BasketItem/BasketItem";
import { useCheckoutStore } from "@/store/CheckoutStore";
import { ButtonVariants } from "@/types/inputFields";

import Styles from "./basket.module.scss";

export const Basket = () => {
	const [checkout] = useUserDataStore((state) => [state.checkout]);
	const [basketOpen, setBasketOpen] = useCheckoutStore((state) => [
		state.basketOpen,
		state.setBasketOpen,
	]);
	const { status } = useSession();

	return (
		<>
			<button
				className={Styles.basketIcon}
				onClick={() => setBasketOpen(!basketOpen)}
			>
				<Icon icon="Basket" height="36px" width="36px" />
			</button>
			<div
				className={c(Styles.basketBackground, { [Styles.bgOpen]: basketOpen })}
				onClick={() => setBasketOpen(false)}
			></div>
			<div className={c(Styles.basket, { [Styles.open]: basketOpen })}>
				<h4>Your basket</h4>
				<div>
					{checkout?.lineItems?.map((item, key) => (
						<BasketItem key={key} item={item} />
					))}
				</div>
				<div>
					<PriceSummary showDelivery={false} colour="light" />
				</div>
				{(checkout?.lineItems?.length || 0) > 0 && (
					<Button
						url="/checkout"
						className={Styles.checkoutBtn}
						variant={ButtonVariants.default}
					>
						Go to checkout
					</Button>
				)}
				{status === "unauthenticated" && (
					<>
						<p className="bright tiny-bottom text-center">Existing customer?</p>
						<Button
							variant={ButtonVariants.ghostLight}
							className={Styles.logInBtn}
							sizing="smaller"
							url="/account/shop"
							underline
						>
							Log in for free delivery
						</Button>
					</>
				)}
			</div>
		</>
	);
};
