export enum AmplitudeExperiments {
	PRICETEST = "price-test",
	SENSITIVE_CLARITY = "sensitive-clarity",
	RENAL_CLARITY = "renal-clarity",
	EXPRESS_CHECKOUT = "express-checkout",
	SOCIAL_PROOF = "social-proof",
	CANCEL_VOUCHERS = "cancel-vouchers",
	WEIGHT_RANGE = "weight-range",
	CANCEL_GPT = "cancel-gpt",
	ASK_BRAND = "ask-brand",
	ASK_PROBLEM = "ask-problem",
	CANCEL_TOPPERS = "cancel-toppers",
}

export type ABVariants = Record<string, "treatment" | "control" | "off">;

export type ExperimentContext = {
	loading: boolean;
	variants: ABVariants;
};

export type ExperimentItem = {
	key: string;
	user_id?: string;
};
