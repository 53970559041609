import { useCallback, useEffect, useMemo, useRef, useContext } from "react";

import { useRouter } from "next/router";

import { useFunnelStepForm } from "@/pagesComponents/Funnel/FunnelStepForm/useFunnelStepForm";
import {
	AmplitudeExperimentContext,
	AmplitudeExperiments,
} from "@/providers/ExperimentsProvider";

import {
	useAccountDataStore,
	useFunnelStore,
	useUserDataStore,
} from "../store";
import { FunnelData } from "../types";

export enum FunnelRoutes {
	INDEX = "Index",
	HELP = "Help",
	NAME = "Name",
	GENDER = "Gender",
	AGE = "Age",
	NEUTERED = "Neutered",
	BREED = "Breed",
	WEIGHT = "Weight",
	HEALTH = "Health",
	DIET = "Diet",
	SHAPE = "Shape",
	SKIN_AND_COAT = "Skin and Coat",
	MOBILITY = "Mobility",
	DIGESTION = "Digestion",
	ORAL = "Oral",
	BEHAVIOURAL = "Behavioural",
	AILMENTS = "Ailments",
	ALLERGIES = "Allergies",
	FUSSINESS = "Fussiness",
	TREATS = "Treats",
	STOOLS = "Stools",
	ACTIVITY = "Activity",
	EMAIL = "Email",
}

const URLStructure = "/";

export const Routes = {
	[FunnelRoutes.INDEX]: URLStructure + "signup",
	[FunnelRoutes.HELP]: URLStructure + "signup/help",
	[FunnelRoutes.NAME]: URLStructure + "signup/name",
	[FunnelRoutes.GENDER]: URLStructure + "signup/gender",
	[FunnelRoutes.AGE]: URLStructure + "signup/age",
	[FunnelRoutes.NEUTERED]: URLStructure + "signup/neutered",
	[FunnelRoutes.BREED]: URLStructure + "signup/breed",
	[FunnelRoutes.WEIGHT]: URLStructure + "signup/weight",
	[FunnelRoutes.SHAPE]: URLStructure + "signup/shape",
	[FunnelRoutes.ALLERGIES]: URLStructure + "signup/allergies",
	[FunnelRoutes.DIET]: URLStructure + "signup/diet",
	[FunnelRoutes.FUSSINESS]: URLStructure + "signup/fussy",
	[FunnelRoutes.TREATS]: URLStructure + "signup/treats",
	[FunnelRoutes.ACTIVITY]: URLStructure + "signup/activity",
	[FunnelRoutes.AILMENTS]: URLStructure + "signup/ailments",
	[FunnelRoutes.EMAIL]: URLStructure + "signup/email",
	[FunnelRoutes.HEALTH]: URLStructure + "signup/health",
	[FunnelRoutes.SKIN_AND_COAT]: URLStructure + "signup/coat",
	[FunnelRoutes.MOBILITY]: URLStructure + "signup/mobility",
	[FunnelRoutes.DIGESTION]: URLStructure + "signup/digestion",
	[FunnelRoutes.STOOLS]: URLStructure + "signup/stools",
	[FunnelRoutes.ORAL]: URLStructure + "signup/oralhealth",
	[FunnelRoutes.BEHAVIOURAL]: URLStructure + "signup/behaviour",
};

type Props = {
	stepName?: FunnelRoutes;
};

export const useFunnelNavigator = ({ stepName }: Props) => {
	const { push, query, prefetch } = useRouter();
	const { email } = useUserDataStore((state) => state.user);
	const [user] = useAccountDataStore((state) => [state.user]);

	const experiments = useContext(AmplitudeExperimentContext);

	const askProblemFlag = useMemo(() => {
		return (
			experiments.variants[AmplitudeExperiments.ASK_PROBLEM] === "treatment"
		);
	}, [experiments.variants]);

	const data = useFunnelStore((state) => state.data);

	const aDogCanBeNeutered = useMemo(
		() =>
			!!data.find(({ years, months }) => (years || 0) > 0 || (months || 0) > 4),
		[data]
	);

	const hasPushedRoute = useRef(false);

	const stepOrder = useMemo(() => {
		const neuteredStep = aDogCanBeNeutered ? [FunnelRoutes.NEUTERED] : [];

		const preHealthQuestions = [
			FunnelRoutes.INDEX,
			...(askProblemFlag ? [FunnelRoutes.HELP, FunnelRoutes.NAME] : []),
			FunnelRoutes.GENDER,
			FunnelRoutes.AGE,
			...neuteredStep,
			FunnelRoutes.BREED,
			FunnelRoutes.WEIGHT,
			FunnelRoutes.ACTIVITY,
		];

		const postImproveHealthQuestions = [
			// Improve health specific
			FunnelRoutes.SHAPE,
			FunnelRoutes.SKIN_AND_COAT,
			FunnelRoutes.MOBILITY,
			FunnelRoutes.DIGESTION,
			FunnelRoutes.STOOLS,
			FunnelRoutes.ORAL,
			FunnelRoutes.BEHAVIOURAL,
			// End Improve health specific

			FunnelRoutes.AILMENTS,
			FunnelRoutes.ALLERGIES,
			FunnelRoutes.DIET,
			FunnelRoutes.FUSSINESS,
			FunnelRoutes.TREATS,
		];

		const emailStep = email || user?.email ? [] : [FunnelRoutes.EMAIL];

		return [...preHealthQuestions, ...postImproveHealthQuestions, ...emailStep];
	}, [aDogCanBeNeutered, askProblemFlag, email, user?.email]);

	const currentIndex = stepOrder?.findIndex((item) => item === stepName);

	const nextStep =
		currentIndex || currentIndex === 0 ? stepOrder?.[currentIndex + 1] : null;

	useEffect(() => {
		if (!nextStep) {
			return;
		}

		const next = nextStep as keyof typeof Routes;

		Routes[next] && prefetch(Routes[next]);
	}, [nextStep, prefetch]);

	const navigateAfterFunnel = useCallback(
		(iteration: number) => {
			if (!hasPushedRoute.current) {
				hasPushedRoute.current = true;

				push(`../signup-menu?iteration=${iteration}`);
			}
		},
		[push]
	);

	const goToLastStep = useCallback(() => {
		const finalStep = stepOrder[stepOrder.length - 1];
		push({ pathname: `${Routes[finalStep as keyof typeof Routes]}`, query });
	}, [push, query, stepOrder]);

	const goToFirstStep = useCallback(() => {
		const firstStep = stepOrder[0];
		push({ pathname: `${Routes[firstStep as keyof typeof Routes]}`, query });
	}, [push, query, stepOrder]);

	const onComplete = useCallback(
		(iteration: number) => {
			navigateAfterFunnel(iteration);
		},
		[navigateAfterFunnel]
	);

	const { completeForm, isWorking: isLoadingFunnelData } = useFunnelStepForm({
		onComplete,
		goToFirstStep,
	});

	const goToNextStep = useCallback(
		(
			newEmail?: string,
			newFirst?: string,
			newOptIn?: boolean,
			newFunnelData?: FunnelData[]
		) => {
			if (
				(typeof stepOrder?.[currentIndex + 1] === "undefined" &&
					currentIndex === stepOrder.length - 1) ||
				FunnelRoutes.EMAIL === stepName
			) {
				console.trace("RUNNING GO TO NEXT STEP");
				completeForm(newEmail, newFirst, newOptIn, newFunnelData);

				return;
			}

			if (!nextStep) {
				console.error("An error occured finding the route");

				return;
			}

			window.scrollTo({ top: 0, behavior: "instant" });

			push({
				pathname: `${Routes[nextStep as keyof typeof Routes]}`,
				query,
			});
		},
		[completeForm, currentIndex, nextStep, push, query, stepName, stepOrder]
	);

	const goBackAStep = useCallback(() => {
		const previousStep = stepOrder?.[currentIndex - 1];

		if (!previousStep && FunnelRoutes.EMAIL === stepName) {
			goToLastStep();

			return;
		}

		if (previousStep) {
			push({
				pathname: `${Routes[previousStep as keyof typeof Routes]}`,
				query,
			});
		}
	}, [stepOrder, currentIndex, stepName, goToLastStep, push, query]);

	return {
		goToNextStep,
		goBackAStep,
		stageNo: currentIndex + 1 || 20,
		totalSteps: stepOrder.length,
		navigateAfterFunnel,
		goToLastStep,
		buttonBarProps: {
			isFirstStep: currentIndex === 0,
			isLastStep:
				(stepOrder && stepOrder?.length - 1 === currentIndex) ||
				FunnelRoutes.EMAIL === stepName,
			onBack: goBackAStep,
		},
		isLoadingFunnelData,
		goToFirstStep,
	};
};
