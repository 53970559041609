export const TAG_VALUES = {
	FUSSY: "Loved by fussy dogs",
	NEW: "New",
	POPULAR: "Popular",
	POPULARWITHPUPPIES: "Popular with puppies",
};

export enum SCOOP_SIZES {
	FOOD = 33, // grams
	WATER = 45, // ml
	WATER_SENSITIVE = 75,
}
