import c from "classnames";
import Link from "next/link";

import Styles from "./SplitHeading.module.scss";

type Props = {
	line1: string;
	line2?: string;
	line3?: string;
	colour?: "orange" | "green" | "purple" | "yellow" | "dark" | "white";
	heirarchy?: "h1" | "h2" | "h3";
	className?: string;
	children?: React.ReactNode;
	subtitle?: string;
	link?: string;
};

export const SplitHeading = ({
	line1,
	line2,
	line3,
	colour = "orange",
	heirarchy = "h2",
	className = "",
	children = null,
	subtitle,
	link,
}: Props) => {
	const Title = heirarchy;

	const content = (
		<Title
			className={c(className, Styles.splitHeading, {
				[Styles[colour]]: colour !== "orange",
			})}
		>
			{line1 + " "}
			{line2 && <span>{line2 + " "}</span>}
			{line3 && <span>{line3 + " "}</span>}
			{subtitle && <span className={Styles.subtitle}>{subtitle + " "}</span>}
			{children}
		</Title>
	);

	if (link) {
		return (
			<Link href={link}>
				<a>{content}</a>
			</Link>
		);
	} else {
		return content;
	}
};
